<template>
  <simpleNotifyPage
    :action="action"
    barType="error"
    hasTextarea
    textareaLabel="請告訴客戶無法供貨的原因："
  >
    <template v-slot:title> 拒絕申請 </template>
    <template v-slot:subtitle> {{ storeName }}未通過申請 </template>
    <template v-slot:text-label> 合作狀態： </template>
    <template v-slot:text-value> 已拒絕 </template>
    <template v-slot:btn-text>送出</template>
  </simpleNotifyPage>
</template>

<script>
export default {
  components: {
    simpleNotifyPage: () =>
      import("@/modules/base/views/notifyPage/simpleNotifyPage.vue"),
  },
  computed: {
    storeName() {
      return this.$route.query.name;
    },
    storeId() {
      return this.$route.query.storeId;
    },
    providerId() {
      return this.$route.query.providerId;
    },
  },
  methods: {
    async action(reason) {
      this.$store.dispatch("loading/active");
      try {
        const params = {
          status: this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REJECT,
          reason,
        };
        await this.$api.collection.providerApi.updateStatus(
          this.providerId,
          this.storeId,
          params
        );
        this.$snotify.success(this.$t("action.cooperation.reject.success"));
        await this.$helper.closeLiff(1)
      } catch (err) {
        console.error(err);
        this.$snotify.error(this.$t("action.cooperation.reject.fail"));
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
  },
};
</script>
